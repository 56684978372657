.table-wrapper {
    width: 100%;
    overflow-x: auto;
}

.table {
    overflow: hidden;
    table-layout: auto;
    border-collapse: collapse;
    box-shadow: 0 10px 10px #ccc;
    border-radius: 10px;
    white-space: nowrap;
    border: 1px solid rgb(190, 190, 190);
    width: 100em;
    max-width: 80%;
    margin: auto;
    overflow-x: auto;
}

@media screen and (max-width: 600px) {
    .table {
        width: 100%;
    }
}

.table thead {
    background-color: #ccc;
    color: #222;
}

.table th,
.table td {
    padding: 0.8rem;
}

.table td {
    border-top: 0.5px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.table tbody tr:hover {
    background-color: #eee;
}

.label {
    border-radius: 3px;
    padding: 0.3rem;
    color: white;
}

.label-yes {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: rgb(154, 255, 107);
    color: #000;
}

.label-no {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: rgb(255, 107, 107);
    color: #000;
}

.actions {
    display: flex;
    justify-content: space-around;
}

.actions svg {
    cursor: pointer;
}

.delete-btn {
    color: #e10d05;
}

.update-btn {
    color: #15a1e2;
}

.send-email {
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    color: #000;
    background: #5eecff;
    transition: 0.3s ease-in-out;
}

.send-email:hover {
    color: #fff;
    background: #005a66;
}